/* Autocomplete.module.css */
.autocomplete {
  position: relative;
}

.resultList {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 90%;
  z-index: 1000;
  /* Set a high z-index value to appear above other components */
}

.Autocomplete_autocomplete {
  background-image: url("./images/search.svg");
  background-repeat: no-repeat;
  background-position: left 8px center;
  padding: 12px 30px 12px 40px;
  border: 1px solid #c4c4c4;
  border-radius: 60px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #212121;
  width: 100%;
  font-family: "Roboto";
}
