.modal {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Fixed position ensures the modal stays in view */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent background overlay */
        z-index: 1000;
        /* A high z-index to ensure it appears on top of other elements */
        align-items: center;
        justify-content: center;
}

/* Modal content */
.modal-content {
        background-color: #fff;
        width:800px;
        height: 200px;
        /* White background for the modal content */
        padding: 20px;
        border-radius: 8px;
}

.overlay {
        position: fixed;
        /* Fixed positioning to cover the entire viewport */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent black background */
        z-index: 1000;
        /* A high z-index to ensure it appears on top of other elements */
        display: flex;
        align-items: center;
        justify-content: center;
}

