@import "colors";
@import "fonts";
@import "media_queries";
@import "common";
@import "mixins";

.login-container {
  // height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  @include max(lg) {
    top: unset;
    left: unset;
    transform: unset;
  }
  .content-box {
    background-color: $white;
    border-radius: 35px;
    box-shadow: 5px 5px 60px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    @include max(lg) {
      box-shadow: none;
    }
    .left-container {
      width: 100%;
      @include max(md) {
        height: 200px;
      }
      @include minmax(md, lg) {
        height: 350px;
      }
      @include minmax(lg, xl) {
        height: 100%;
      }
      img {
        height: 100%;
        width: 100%;
        @include max(xl) {
          object-fit: none;
        }
      }
    }
    .right-container {
      max-width: 380px;
      @include max(md) {
        padding: 30px !important;
        border-radius: 30px 30px 0 0 !important;
      }
      @include max(lg) {
        margin-top: -50px;
        background-color: $white;
        border-radius: 50px 50px 0 0;
        padding: 65px 100px 30px;
        max-width: 100%;
        padding-top: 65px;
      }
      // @include max(lg){
      //   padding-right: 25px;
      // }
      @include minmax(lg, xl) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .info-text {
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        font-family: "Roboto";
      }
      .password-suggest {
        .form-check-label {
          @extend .common-size;
          color: $light-black;
        }
        a {
          @extend .common-size;
          color: $skyblue;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 0;
            height: 2px;
            border-radius: 4px;
            background-color: $skyblue;
            transition: 0.4s;
          }
          &:hover {
            &:after {
              width: 100%;
              transition: 0.4s;
            }
          }
        }
      }
      .btn {
        &.btn-secondary {
          img {
            @include max(md) {
              width: 25px;
            }
          }
        }
      }
      .signup-info {
        span {
          @extend .common-size;
          color: $light-black;
        }
        a {
          @extend .common-size;
          color: $skyblue;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 0;
            height: 2px;
            border-radius: 4px;
            background-color: $skyblue;
            transition: 0.4s;
          }
          &:hover {
            &:after {
              width: 100%;
              transition: 0.4s;
            }
          }
        }
      }
      .otp-container {
        .form-control {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          color: #9b9b9b;
          @extend .common-size;
          @include max(md) {
            width: 40px;
            height: 40px;
          }
        }
      }
      .pass-sec {
        position: relative;
        .pass-view {
          position: absolute;
          top: 50%;
          right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

//Dashboard Style

header {
  .dasboard-header {
    background-image: url("../images/header-img.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
    .dashboard-item {
      padding-left: 40px;
      padding-right: 40px;
      @include max(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .header-section {
      padding: 10px 0px 35px;
      .profile {
        display: flex;
        align-items: center;
        .pro-picture {
          display: block;
          width: 35px;
          height: 35px;
          overflow: hidden;
          border-radius: 13px;
          //margin-left: 14px;
          cursor: pointer;
          border: 2px solid #39bbff;
          flex: 0 0 35px;
          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }
        .dropdown {
          .btn {
            border: 0;
            padding: 0;
            &.btn-default {
              &:hover {
                border: 0;
              }
            }
            &.dropdown-toggle {
              &:after {
                border-top: 0.3em solid $white;
              }
            }
          }
          .dropdown-menu {
            transform: translate(0px, 40px) !important;
            @include max(md) {
              transform: translate(0px, 20px) !important;
            }
          }
        }
        span {
          font-size: 16px;
          font-weight: 500;
          margin-left: 15px;
          color: $white;
          font-family: "Roboto";
        }
      }
    }
  }
}

//Dashboard-container
.common-box {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 30px 0 rgba(196, 196, 196, 0.25);
}

.underline-effect{
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 0;
    height: 2px;
    border-radius: 4px;
    background-color: $skyblue;
    transition: 0.4s;
  }
  &:hover {
    &:after {
      width: 100%;
      transition: 0.4s;
    }
  }
}

//info-card
.info-card {
  padding: 20px;
  @extend .common-box;
  .ssp-holder {
    .ssp-name {
      font-size: 13px;
      line-height: 18px;
      font-weight: 300;
      font-family: "Roboto";
      color: $primary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .switch-wrap {
      .switch-check {
        position: relative;
        width: 35px;
        height: 18px;
        -webkit-appearance: none;
        appearance: none;
        background: #f1f1f1;
        outline: none;
        border-radius: 2rem;
        cursor: pointer;
        // box-shadow: inset 0 0 5px rgba(0 0 0 .5);
        &:before {
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: #9b9b9b;
          position: absolute;
          top: 2px;
          left: 3px;
          transition: 0.5s;
        }
        &:checked {
          background: #f1f1f1;
          &:before {
            transform: translateX(100%);
            background: #4285f4;
          }
        }
      }
    }
  }
  .status-holder {
    .link-wrap {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin-right: 20px;
      > span{
        > i{
          font-size: 32px;
        }
      }
    }
    .income-wrap {
      margin-right: 20px;
      .money-value {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        color: $primary;
        font-family: "Roboto";
        @include max(md){
          font-size: 20px;
          line-height: 25px;
        }
      }
      .caption-text {
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        color: #c4c4c4;
        font-family: "Roboto";
      }
    }
    .value-wrap {
      .carat {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #c4c4c4;
      }
      .value-text {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        font-family: "Roboto";
      }
      &.increment {
        .carat {
          border-top: 4px solid #61d17f;
          transform: rotate(180deg);
        }
        .value-text {
          color: #61d17f;
        }
      }
      &.decrement {
        .carat {
          border-top: 4px solid #ea4335;
        }
        .value-text {
          color: #ea4335;
        }
      }
    }
  }
}

.dashboard-container {
  background-color: #f5f6f9;
  border-radius: 20px 20px 0px 0px;
  padding: 40px 100px;
  margin-top: -17px;
  position: relative;
  z-index: 10;
  overflow-y: auto;
  height: calc(100vh - 73px);
  @include max(xxl){
    padding: 40px;
  }
  @include max(xl){
    padding: 40px 40px;
  }
  @include max(md){
    padding: 20px 20px;
  }
  .dashboard-heading {
    margin-bottom: 30px;
    @include max(lg){
      margin-bottom: 15px;
    }
    span {
      &.heading-text {
        font-size: 26px;
        line-height: 30px;
        font-weight: 500;
        color: $primary;
        font-family: "Roboto";
        @include max(md){
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  .view-all-link{
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    color: $primary;
    font-family: "Roboto";
  }
  .card-container {
    .card-row {
      margin: 0 -8px;
      overflow-x: auto;
      @include max(xxl){
        margin: 0 auto;
        justify-content: space-between;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-moz-scrollbar {
        height: 5px;
      }
      &::-ms-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 5px;
      }
      &::-moz-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 5px;
      }
      &::-ms-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 5px;
      }
      .card-col {
        flex: 0 0 320px;
        width: 302px;
        padding: 10px 8px;
        @include max(xl){
          flex: 0 0 33.3333%;
          width: 33.3333%;
        }
        @include max(lg){
          flex: 0 0 50%;
          width: 50%;
        }
        @include max(md){
          flex: 0 0 100%;
          width: 100%;
        }
        &:nth-child(4n + 1){
          .info-card{
            .status-holder{
              .link-wrap{
                background-color: #e8f7ff;
                > span{
                  > i{
                    color: #39BCFF;
                  }
                }
              }
            }
          }
        }
        &:nth-child(4n + 2){
          .info-card{
            .status-holder{
              .link-wrap{
                background-color: #FDEDF3;
                > span{
                  > i{
                    color: #F16297;
                  }
                }
              }
            }
          }
        }
        &:nth-child(4n + 3){
          .info-card{
            .status-holder{
              .link-wrap{
                background-color: #FFF8E8;
                > span{
                  > i{
                    color: #FBBC05;
                  }
                }
              }
            }
          }
        }
        &:nth-child(4n + 4){
          .info-card{
            .status-holder{
              .link-wrap{
                background-color: #EBF0FF;
                > span{
                  > i{
                    color: #4285F4;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .chart-container{
    .chart-box{
      @extend .common-box;
      padding: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100%;
    }
  }
  .advertisment-info-table{
    .advertisment-table-container{
      @extend .common-box;
      padding: 45px 40px;
      @include max(md){
        padding: 20px 15px;
      }
      .table-link-wrap{
        position: relative;
        &:after,&:before{
          content: "";
          position: absolute;
          height: 31px;
          width: 35px;
          top: 0;
        }
        &:after{
          right: 0;
          background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
        }
        &:before{
          left: 0;
          background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        }
        .link-btn-holder{
          overflow-x: auto;
          &::-webkit-scrollbar {
            height: 5px;
          }
          &::-moz-scrollbar {
            height: 5px;
          }
          &::-ms-scrollbar {
            height: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(196, 196, 196, 0.3);
            border-radius: 5px;
          }
          &::-moz-scrollbar-thumb {
            background: rgba(196, 196, 196, 0.3);
            border-radius: 5px;
          }
          &::-ms-scrollbar-thumb {
            background: rgba(196, 196, 196, 0.3);
            border-radius: 5px;
          }
          .table-link-btn{
            color: #39BBFF;
            text-align: center;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            padding: 5px 15px;
            background-color: #DEF4FF;
            border-radius: 25px;
            margin-right: 15px;
            cursor: pointer;
            transition: 0.3s;
            white-space: nowrap;
            &:hover , &.active{
              background-color: #39BBFF;
              color: #ffffff;
              transition: 0.3s;
            }
            @include max(md){
              font-size: 12px;
              margin-right: 7px;
              padding: 4px 13px;
            }
          }
        }
      }
    }
  }
}

.view-table{
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-moz-scrollbar {
    height: 5px;
  }
  &::-ms-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.3);
    border-radius: 5px;
  }
  &::-moz-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.3);
    border-radius: 5px;
  }
  &::-ms-scrollbar-thumb {
    background: rgba(196, 196, 196, 0.3);
    border-radius: 5px;
  }
  @include max(lg){
    width: 100%;
    overflow-x: auto;
  }
  .table{
    thead{
      tr{
        th{
          color: $light-black;
          font-family: 'Roboto';
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
    tbody{
      tr{
        td{
          color: $primary;
          font-family: 'Roboto';
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          text-align: center;
          padding: 12px 10px;
        }
      }
    }
  }
}

.card-container{
  &.dashboard-view{
    .card-row{
      overflow-x: unset;
      .info-card{
        .ssp-holder{
          .ssp-name{
            @include max(md){
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.ad-info-view-wrap{
  .dashboard-heading{
    margin-bottom: 0;
  }
  .add-option-view{
    @include max(lg){
      width: 100%;
    }
    .btn-group{
      .btn-default{
        border-radius: 25px;
        border: 1px solid #39BBFF;
        background: #FFF;
        color: #39BBFF;
        text-align: center;
        font-family: 'Roboto';
        font-size: 14px;
        font-weight: 600;
        padding: 7px 15px;
      }
    }
    .view-btn{
      position: relative;
      transition: 0.3s;
      .active-icon{
        position: absolute;
        top: -7px;
        left: 0;
        opacity: 0;
      }
      &:hover{
        .active-icon{
          opacity: 1;
          transition: 0.3s;
        }
        .in-active-icon{
          opacity: 0;
          transition: 0.3s;
        }
      }
    }
  }
  .result-view-container{
    @extend .common-box;
    padding: 45px 40px;
    box-shadow: 0 4px 30px 0 rgba(196, 196, 196, 0.25);
    @include max(md){
      padding: 20px 15px;
    }
    .advertisment-table-container{
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
    .result-view-chart{
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-moz-scrollbar {
        height: 5px;
      }
      &::-ms-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 5px;
      }
      &::-moz-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 5px;
      }
      &::-ms-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.3);
        border-radius: 5px;
      }
      @include max(lg){
        width: 100%;
        overflow-x: auto;
      }
    }
  }
}

.bid-area-container{
  @extend .common-box;
  padding: 40px 50px;
  @include max(md){
    padding: 20px 15px;
  }
  .nav{
    .nav-item{
      @include max(md){
        width: 100%;
      }
      .nav-link{
        border-radius: 53px;
        background: #F5F6F9;
        padding: 14px 23px;
        color: $ash;
        font-family: 'Roboto';
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        transition: 0.3s;
        img{
          @include max(md){
            width: 15px;
          }
        }
        &.active{
          background: #39BBFF;
          color: $white;
          img{
            filter: brightness(100);
          }
        }
        &:hover{
          background: #39BBFF;
          color: $white;
          transition: 0.3s;
          img{
            filter: brightness(100);
          }
        }
        @include max(md){
          width: 100%;
          padding: 10px 23px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .tab-content{
    .tab-pane{
      &.time-wrap{
        .nav{
          .nav-item{
            .nav-link{
              background-color: #ECF4FE;
              border-radius: 0;
              border-right: 1px dotted $black;
              color: #655F5F;
              font-family: 'Roboto';
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              &:hover , &.active{
                background: #F5F6F9;
              }
              @include max(md){
                border-right: 0;
              }
            }
            &:first-child{
              .nav-link{
                border-radius: 10px 0 0 0;
                @include max(md){
                  border-radius: 15px 15px 0 0;
                }
              }
            }
            &:last-child{
              .nav-link{
                border-right: 0;
                border-radius: 0 10px 0 0;
                @include max(md){
                  border-radius: 0;
                }
              }
            }
          }
        }
        .tab-content{
          &.time-opt{
            background-color: #F5F6F9;
            border-radius: 30px;
            max-width: 650px;
            width: 100%;
            padding: 30px;
            @include max(md){
              border-radius: 0 0 15px 15px;
            }
            .tab-pane{
              .time-fill-box{
                .form-label{
                  color: #655F5F;
                  font-family: 'Roboto';
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 500;
                  margin-bottom: 3px;
                }
                .form-control{
                  width: 65px;
                  height: 40px;
                  border: 0;
                  border-radius: 10px;
                  background-color: $white;
                  padding: 5px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-content{
    .tab-pane{
      &.platform{
        background-color: #F5F6F9;
        border-radius: 30px;
        width: 100%;
        padding: 40px 50px;
        @include max(md){
          padding: 30px 25px;
        }
        h2{
          color: $primary;
          font-family: 'Roboto';
          font-size: 26px;
          font-weight: 500;
          line-height: 30px;
          @include max(md){
            font-size: 18px;
            line-height: 22px;
          }
        }
        .range-row{
          margin-left:-15px;
          margin-right: -15px;
          .range-col{
            flex: 0 0 25%;
            width: 25%;
            padding: 0 15px 35px 15px;
            &:last-child{
              @include max(md){
                padding-bottom: 0;
              }
            }
            @include max(lg){
              flex: 0 0 50%;
              width: 50%;
            }
            @include max(md){
              flex: 0 0 100%;
              width: 100%;
              padding-bottom: 15px;
            }
            .range-desc{
              .title{
                color: #655F5F;
                font-family: 'Roboto';
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
              }
              .percentage{
                color: #C4C4C4;
                font-family: 'Roboto';
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
              }
            }
            .range-slider{
              width: 100%;
              .range{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .tab-content{
    .tab-pane{
      &.platform{
        &.result-others{
          max-width: 650px;
          width: 100%;
          padding: 30px;
          margin: auto;
          .range-row{
            .range-col{
              flex: 0 0 50%;
              width: 50%;
              padding-bottom: 0;
              @include max(md){
                flex: 0 0 100%;
                width: 100%;
                padding-bottom: 15px;
                &:last-child{
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}