@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../font/Roboto-Bold.ttf');
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../font/Roboto-Medium.ttf');
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../font/Roboto-Regular.ttf');
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('../font/Roboto-Light.ttf');
    font-display: swap;
    font-weight: 300;
}






