html {
    scroll-behavior: smooth;
}

//Mine

body {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
}

::-moz-scrollbar {
    width: 5px;
}

::-ms-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #64616E;
}

::-moz-scrollbar-thumb {
    background: #64616E;
}

::-ms-scrollbar-thumb {
    background: #64616E;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    >a {
        color: inherit !important;
    }
}

h1,
.h1_styled {
    font-size: 45px;
    line-height: 50px;
    color: $primary;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    @include max(md){
        font-size: 25px;
        line-height: 30px;
    }
}

h2,
.h2_styled {
    
    @include max(md){
        
    }
}

h3,
.h3_styled {
    
    @include max(md){
        
    }
}

p {
    
    @include max(md){
        
    }
}

img {
    max-width: 100%;
}


a {
    //font-size: 16px;
    &.link {
       
    }
}

a {
    //color: $white;
    transition: 0.5s;
    text-decoration: none;
    &:hover {
        // color: $primary;
        text-decoration: none !important;
    }
}

ul{
    &.no-style{
        padding: 0;
        list-style: none;
    }
}


.btn {
    font-size: 16px;
    line-height: 18px;
    font-family: 'Roboto';
    font-weight: 700;
    transition: 0.3s;
}

.btn-primary {
    width: 100%;
    background-color: $black;
    color: $white;
    border: 1px solid $black;
    padding: 21px 10px;
    border-radius: 60px;
    transition: 0.3s;
    &:hover{
        background-color: $skyblue;
        color: $white;
        border: 1px solid $skyblue;
        transition: 0.3s;
    }
    &:focus{
        box-shadow: none;
        background-color: $skyblue;
        border-color: $skyblue;
    }
    @include max(md){
        padding: 12px 20px;
    }
}

.btn-secondary {
    width: 100%;
    background-color: $white;
    color: #3C4043;
    border: 1px solid #C4C4C4;
    padding: 13px 10px;
    border-radius: 60px;
    transition: 0.3s;
    &:hover{
        background-color: $white;
        color: #3C4043;
        border: 1px solid #C4C4C4;
        transition: 0.3s;
    }
    &:focus{
        box-shadow: none;
        background-color: $white;
        border-color: #C4C4C4;
        color: #3C4043;
    }
    @include max(md){
        padding: 12px 10px;
    }
}

.form-label{
    font-size: 16px;
    line-height: 14px;
    color: $light-black;
    font-weight: 500;
    margin-bottom: 12px;
    @include max(md){
        font-size: 14px;
    }
}

.form-control {
    border: 1px solid #C4C4C4;
    border-radius: 60px;
    padding: 20px 30px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: $primary;
    width: 100%;
    font-family: 'Roboto';
    &:focus {
        background-color: #fff;
        border-color: #C4C4C4;
        outline: unset;
        box-shadow: none;
    }
    &::placeholder{
        color: $ash;
        font-size: 14px;
        line-height: 16px;
        font-family: 'Roboto';
    }
    @include max(md){
        padding: 10px 20px;
    }
}

.form-check-input{
    &:focus{
        box-shadow: none;
    }
}

.common-size{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'Roboto';
}

.save-btn{
    color: $white;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    border-radius: 60px;
    background: #39BBFF;
    padding: 18px 38px;
    border: 1px solid #39BBFF;
    @include max(md){
        padding: 10px 10px;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    width: 100%;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 6px;
  }
  
  /* Track: webkit browsers */
  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    background: #D9D9D9;
    border-radius: 6px;
  }
  
  /* Track: Mozilla Firefox */
  input[type="range"]::-moz-range-track {
    height: 10px;
    background: #D9D9D9;
    border-radius: 6px;
  }
  
  /* Thumb: webkit */
  input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    height:10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #39BBFF;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #39BBFF;
  }
  
  
  /* Thumb: Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #39BBFF;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #39BBFF;
  }