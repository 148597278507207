/*
* colors
* --------------------------------------------------
*/
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../font/Roboto-Bold.ttf");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../font/Roboto-Medium.ttf");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../font/Roboto-Regular.ttf");
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../font/Roboto-Light.ttf");
  font-display: swap;
  font-weight: 300;
}
html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-moz-scrollbar {
  width: 5px;
}

::-ms-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #64616e;
}

::-moz-scrollbar-thumb {
  background: #64616e;
}

::-ms-scrollbar-thumb {
  background: #64616e;
}

#search-box {
  border: 2px solid gray;
  border-radius: 5px;
  min-width: 14rem;
  width: 40%;
  padding-left: 0.5rem;
}
#search-box-div {
  width: 95%;
  height: 3rem;
  margin-bottom: 15px;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#loader-box {
  width: 100%;
  height: 3rem;
  margin: 2rem;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  color: inherit !important;
}

h1,
.h1_styled {
  font-size: 45px;
  line-height: 50px;
  color: #212121;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 767px) {
  h1,
  .h1_styled {
    font-size: 25px;
    line-height: 30px;
  }
}

img {
  max-width: 100%;
}

a {
  transition: 0.5s;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}

ul.no-style {
  padding: 0;
  list-style: none;
}

.btn {
  font-size: 16px;
  line-height: 18px;
  font-family: "Roboto";
  font-weight: 700;
  transition: 0.3s;
}

.btn-primary {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  padding: 21px 10px;
  border-radius: 60px;
  transition: 0.3s;
}

.btn-primary:hover {
  background-color: #39bcff;
  color: #ffffff;
  border: 1px solid #39bcff;
  transition: 0.3s;
}

.btn-primary:focus {
  box-shadow: none;
  background-color: #39bcff;
  border-color: #39bcff;
}

@media (max-width: 767px) {
  .btn-primary {
    padding: 12px 20px;
  }
}

.btn-secondary {
  width: 100%;
  background-color: #ffffff;
  color: #3c4043;
  border: 1px solid #c4c4c4;
  padding: 13px 10px;
  border-radius: 60px;
  transition: 0.3s;
}

.btn-secondary:hover {
  background-color: #ffffff;
  color: #3c4043;
  border: 1px solid #c4c4c4;
  transition: 0.3s;
}

.btn-secondary:focus {
  box-shadow: none;
  background-color: #ffffff;
  border-color: #c4c4c4;
  color: #3c4043;
}

@media (max-width: 767px) {
  .btn-secondary {
    padding: 12px 10px;
  }
}

.form-label {
  font-size: 16px;
  line-height: 14px;
  color: #655f5f;
  font-weight: 500;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .form-label {
    font-size: 14px;
  }
}

.form-control {
  border: 1px solid #c4c4c4;
  border-radius: 60px;
  padding: 20px 30px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #212121;
  width: 100%;
  font-family: "Roboto";
}

.form-control:focus {
  background-color: #fff;
  border-color: #c4c4c4;
  outline: unset;
  box-shadow: none;
}

.form-control::placeholder {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 16px;
  font-family: "Roboto";
}

@media (max-width: 767px) {
  .form-control {
    padding: 10px 20px;
  }
}

.form-check-input:focus {
  box-shadow: none;
}

.common-size,
.login-container
  .content-box
  .right-container
  .password-suggest
  .form-check-label,
.login-container .content-box .right-container .password-suggest a,
.login-container .content-box .right-container .signup-info span,
.login-container .content-box .right-container .signup-info a,
.login-container .content-box .right-container .otp-container .form-control {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-family: "Roboto";
}

.save-btn {
  color: #ffffff;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 60px;
  background: #39bbff;
  padding: 18px 38px;
  margin: 0px 16px;
  border: 1px solid #39bbff;
}

@media (max-width: 767px) {
  .save-btn {
    padding: 10px 10px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 6px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  background: #d9d9d9;
  border-radius: 6px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 10px;
  background: #d9d9d9;
  border-radius: 6px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #39bbff;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #39bbff;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #39bbff;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #39bbff;
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}
@media (max-width: 991px) {
  .login-container {
    top: unset;
    left: unset;
    transform: unset;
  }
}
.login-container .content-box {
  background-color: #ffffff;
  border-radius: 35px;
  box-shadow: 5px 5px 60px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
@media (max-width: 991px) {
  .login-container .content-box {
    box-shadow: none;
  }
}

.login-container .content-box .left-container {
  width: 100%;
}

@media (max-width: 767px) {
  .login-container .content-box .left-container {
    height: 200px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .login-container .content-box .left-container {
    height: 350px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .login-container .content-box .left-container {
    height: 100%;
  }
}
.login-container .content-box .left-container img {
  height: 100%;
  width: 100%;
}
@media (max-width: 1199px) {
  .login-container .content-box .left-container img {
    object-fit: none;
  }
}

.login-container .content-box .right-container {
  max-width: 380px;
}

@media (max-width: 767px) {
  .login-container .content-box .right-container {
    padding: 30px !important;
    border-radius: 30px 30px 0 0 !important;
  }
}
@media (max-width: 991px) {
  .login-container .content-box .right-container {
    margin-top: -50px;
    background-color: #ffffff;
    border-radius: 50px 50px 0 0;
    padding: 65px 100px 30px;
    max-width: 100%;
    padding-top: 65px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .login-container .content-box .right-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.login-container .content-box .right-container .info-text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  font-family: "Roboto";
}

.login-container
  .content-box
  .right-container
  .password-suggest
  .form-check-label {
  color: #655f5f;
}

.login-container .content-box .right-container .password-suggest a {
  color: #39bcff;
  position: relative;
}

.login-container .content-box .right-container .password-suggest a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  border-radius: 4px;
  background-color: #39bcff;
  transition: 0.4s;
}

.login-container .content-box .right-container .password-suggest a:hover:after {
  width: 100%;
  transition: 0.4s;
}
@media (max-width: 767px) {
  .login-container .content-box .right-container .btn.btn-secondary img {
    width: 25px;
  }
}

.login-container .content-box .right-container .signup-info span {
  color: #655f5f;
}

.login-container .content-box .right-container .signup-info a {
  color: #39bcff;
  position: relative;
}

.login-container .content-box .right-container .signup-info a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  border-radius: 4px;
  background-color: #39bcff;
  transition: 0.4s;
}

.login-container .content-box .right-container .signup-info a:hover:after {
  width: 100%;
  transition: 0.4s;
}
.login-container .content-box .right-container .otp-container .form-control {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #9b9b9b;
}
@media (max-width: 767px) {
  .login-container .content-box .right-container .otp-container .form-control {
    width: 40px;
    height: 40px;
  }
}

.login-container .content-box .right-container .pass-sec {
  position: relative;
}

.login-container .content-box .right-container .pass-sec .pass-view {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
}

header .dasboard-header {
  background-image: url("../images/header-img.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
}

header .dasboard-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

header .dasboard-header .dashboard-item {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 767px) {
  header .dasboard-header .dashboard-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

header .dasboard-header .header-section {
  padding: 10px 0px 35px;
}

header .dasboard-header .header-section .profile {
  display: flex;
  align-items: center;
}

header .dasboard-header .header-section .profile .pro-picture {
  display: block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 13px;
  cursor: pointer;
  border: 2px solid #39bbff;
  flex: 0 0 35px;
}

header .dasboard-header .header-section .profile .pro-picture img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

header .dasboard-header .header-section .profile .dropdown .btn {
  border: 0;
  padding: 0;
}

header
  .dasboard-header
  .header-section
  .profile
  .dropdown
  .btn.btn-default:hover {
  border: 0;
}

header
  .dasboard-header
  .header-section
  .profile
  .dropdown
  .btn.dropdown-toggle:after {
  border-top: 0.3em solid #ffffff;
}

header .dasboard-header .header-section .profile .dropdown .dropdown-menu {
  transform: translate(0px, 40px) !important;
}

@media (max-width: 767px) {
  header .dasboard-header .header-section .profile .dropdown .dropdown-menu {
    transform: translate(0px, 20px) !important;
  }
}

header .dasboard-header .header-section .profile span {
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
  color: #ffffff;
  font-family: "Roboto";
}

.common-box,
.info-card,
.dashboard-container .chart-container .chart-box,
.dashboard-container .advertisment-info-table .advertisment-table-container,
.ad-info-view-wrap .result-view-container,
.bid-area-container,
.add-user-container {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 30px 0 rgba(196, 196, 196, 0.25);
}

.underline-effect {
  position: relative;
}
.underline-effect:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  border-radius: 4px;
  background-color: #39bcff;
  transition: 0.4s;
}

.underline-effect:hover:after {
  width: 100%;
  transition: 0.4s;
}

.info-card {
  padding: 20px;
}
.info-card .ssp-holder .ssp-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
  font-family: "Roboto";
  color: #212121;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-card .ssp-holder .switch-wrap .switch-check {
  position: relative;
  width: 35px;
  height: 18px;
  -webkit-appearance: none;
  appearance: none;
  background: #f1f1f1;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
}
.info-card .ssp-holder .switch-wrap .switch-check:before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #9b9b9b;
  position: absolute;
  top: 2px;
  left: 3px;
  transition: 0.5s;
}

.info-card .ssp-holder .switch-wrap .switch-check:checked {
  background: #f1f1f1;
}

.info-card .ssp-holder .switch-wrap .switch-check:checked:before {
  transform: translateX(100%);
  background: #4285f4;
}
.info-card .status-holder .link-wrap {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  margin-right: 20px;
}

.info-card .status-holder .link-wrap > span > i {
  font-size: 32px;
}

.info-card .status-holder .income-wrap {
  margin-right: 20px;
}

.info-card .status-holder .income-wrap .money-value {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  color: #212121;
  font-family: "Roboto";
}
@media (max-width: 767px) {
  .info-card .status-holder .income-wrap .money-value {
    font-size: 20px;
    line-height: 25px;
  }
}
.info-card .status-holder .income-wrap .caption-text {
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
  color: #c4c4c4;
  font-family: "Roboto";
}
.info-card .status-holder .value-wrap .carat {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #c4c4c4;
}
.info-card .status-holder .value-wrap .value-text {
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  font-family: "Roboto";
}
.info-card .status-holder .value-wrap.increment .carat {
  border-top: 4px solid #61d17f;
  transform: rotate(180deg);
}

.info-card .status-holder .value-wrap.increment .value-text {
  color: #61d17f;
}

.info-card .status-holder .value-wrap.decrement .carat {
  border-top: 4px solid #ea4335;
}

.info-card .status-holder .value-wrap.decrement .value-text {
  color: #ea4335;
}

.dashboard-container {
  background-color: #f5f6f9;
  border-radius: 20px 20px 0px 0px;
  padding: 40px 100px;
  margin-top: -17px;
  position: relative;
  z-index: 10;
  overflow-y: auto;
  height: calc(100vh - 73px);
}
@media (max-width: 1399px) {
  .dashboard-container {
    padding: 40px;
  }
}
@media (max-width: 1199px) {
  .dashboard-container {
    padding: 40px 40px;
  }
}
@media (max-width: 767px) {
  .dashboard-container {
    padding: 20px 20px;
  }
}

.dashboard-container .dashboard-heading {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .dashboard-container .dashboard-heading {
    margin-bottom: 15px;
  }
}
.dashboard-container .dashboard-heading span.heading-text {
  font-size: 26px;
  line-height: 30px;
  font-weight: 500;
  color: #212121;
  font-family: "Roboto";
}
@media (max-width: 767px) {
  .dashboard-container .dashboard-heading span.heading-text {
    font-size: 18px;
    line-height: 22px;
  }
}
.dashboard-container .view-all-link {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: #212121;
  font-family: "Roboto";
}
.dashboard-container .card-container .card-row {
  margin: 0 -8px;
  overflow-x: auto;
}
@media (max-width: 1399px) {
  .dashboard-container .card-container .card-row {
    margin: 0 auto;
    justify-content: space-between;
  }
}

.dashboard-container .card-container .card-row::-webkit-scrollbar {
  height: 5px;
}

.dashboard-container .card-container .card-row::-moz-scrollbar {
  height: 5px;
}

.dashboard-container .card-container .card-row::-ms-scrollbar {
  height: 5px;
}

.dashboard-container .card-container .card-row::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}
.dashboard-container .card-container .card-row::-moz-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}
.dashboard-container .card-container .card-row::-ms-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}
.dashboard-container .card-container .card-row .card-col {
  flex: 0 0 320px;
  width: 302px;
  padding: 10px 8px;
}
@media (max-width: 1199px) {
  .dashboard-container .card-container .card-row .card-col {
    flex: 0 0 33.3333%;
    width: 33.3333%;
  }
}
@media (max-width: 991px) {
  .dashboard-container .card-container .card-row .card-col {
    flex: 0 0 50%;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .dashboard-container .card-container .card-row .card-col {
    flex: 0 0 100%;
    width: 100%;
  }
}

/* .dashboard-container .card-container .card-row .card-col:nth-child(4n + 1) .info-card .status-holder .link-wrap {
  background-color: #e8f7ff;
} */

/* .dashboard-container .card-container .card-row .card-col:nth-child(4n + 1) .info-card .status-holder .link-wrap>span>i {
  color: #39BCFF;
}

.dashboard-container .card-container .card-row .card-col:nth-child(4n + 2) .info-card .status-holder .link-wrap {
  background-color: #FDEDF3;
}

.dashboard-container .card-container .card-row .card-col:nth-child(4n + 2) .info-card .status-holder .link-wrap>span>i {
  color: #F16297;
}

.dashboard-container .card-container .card-row .card-col:nth-child(4n + 3) .info-card .status-holder .link-wrap {
  background-color: #FFF8E8;
}

.dashboard-container .card-container .card-row .card-col:nth-child(4n + 3) .info-card .status-holder .link-wrap>span>i {
  color: #FBBC05;
}

.dashboard-container .card-container .card-row .card-col:nth-child(4n + 4) .info-card .status-holder .link-wrap {
  background-color: #EBF0FF;
}

.dashboard-container .card-container .card-row .card-col:nth-child(4n + 4) .info-card .status-holder .link-wrap>span>i {
  color: #4285F4;
} */

.dashboard-container
  .card-container
  .card-row
  .card-col
  .info-card
  .status-holder
  .link-wrap {
  background-color: #ebf0ff;
}

.dashboard-container .chart-container .chart-box {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container .advertisment-info-table .advertisment-table-container {
  padding: 45px 40px;
}

@media (max-width: 767px) {
  .dashboard-container .advertisment-info-table .advertisment-table-container {
    padding: 20px 15px;
  }
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap {
  position: relative;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap:after,
.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap:before {
  content: "";
  position: absolute;
  height: 31px;
  width: 35px;
  top: 0;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap:after {
  right: 0;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap:before {
  left: 0;
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder {
  overflow-x: auto;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder::-webkit-scrollbar {
  height: 5px;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder::-moz-scrollbar {
  height: 5px;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder::-ms-scrollbar {
  height: 5px;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder::-moz-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder::-ms-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder
  .table-link-btn {
  color: #39bbff;
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  padding: 5px 15px;
  background-color: #def4ff;
  border-radius: 25px;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
}

.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder
  .table-link-btn:hover,
.dashboard-container
  .advertisment-info-table
  .advertisment-table-container
  .table-link-wrap
  .link-btn-holder
  .table-link-btn.active {
  background-color: #39bbff;
  color: #ffffff;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .dashboard-container
    .advertisment-info-table
    .advertisment-table-container
    .table-link-wrap
    .link-btn-holder
    .table-link-btn {
    font-size: 12px;
    margin-right: 7px;
    padding: 4px 13px;
  }
}

.view-table::-webkit-scrollbar {
  height: 5px;
}

.view-table::-moz-scrollbar {
  height: 5px;
}

.view-table::-ms-scrollbar {
  height: 5px;
}

.view-table::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}
.view-table::-moz-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}
.view-table::-ms-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}
@media (max-width: 991px) {
  .view-table {
    width: 100%;
    overflow-x: auto;
  }
}
.view-table .table thead tr th {
  color: #655f5f;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}
.view-table .table tbody tr td {
  color: #212121;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-align: center;
  padding: 12px 10px;
}

.card-container.dashboard-view .card-row {
  overflow-x: unset;
}
@media (max-width: 767px) {
  .card-container.dashboard-view .card-row .info-card .ssp-holder .ssp-name {
    text-align: center;
    width: 100%;
  }
}

.ad-info-view-wrap .dashboard-heading {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .ad-info-view-wrap .add-option-view {
    width: 100%;
  }
}
.ad-info-view-wrap .add-option-view .btn-group .btn-default {
  border-radius: 25px;
  border: 1px solid #39bbff;
  background: #fff;
  color: #39bbff;
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  padding: 7px 15px;
}
.ad-info-view-wrap .add-option-view .view-btn {
  position: relative;
  transition: 0.3s;
}
.ad-info-view-wrap .add-option-view .view-btn .active-icon {
  position: absolute;
  top: -7px;
  left: 0;
  opacity: 0;
}
.ad-info-view-wrap .add-option-view .view-btn:hover .active-icon {
  opacity: 1;
  transition: 0.3s;
}
.ad-info-view-wrap .add-option-view .view-btn:hover .in-active-icon {
  opacity: 0;
  transition: 0.3s;
}
/* padding: 45px 40px; */
@media (max-width: 767px) {
  .ad-info-view-wrap .result-view-container {
    padding: 20px 15px;
  }
}
.ad-info-view-wrap .result-view-container .advertisment-table-container {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.ad-info-view-wrap
  .result-view-container
  .result-view-chart::-webkit-scrollbar {
  height: 5px;
}

.ad-info-view-wrap .result-view-container .result-view-chart::-moz-scrollbar {
  height: 5px;
}

.ad-info-view-wrap .result-view-container .result-view-chart::-ms-scrollbar {
  height: 5px;
}

.ad-info-view-wrap
  .result-view-container
  .result-view-chart::-webkit-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.ad-info-view-wrap
  .result-view-container
  .result-view-chart::-moz-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.ad-info-view-wrap
  .result-view-container
  .result-view-chart::-ms-scrollbar-thumb {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

@media (max-width: 991px) {
  .ad-info-view-wrap .result-view-container .result-view-chart {
    width: 100%;
    overflow-x: auto;
  }
}

.bid-area-container {
  padding: 40px 50px;
}
@media (max-width: 767px) {
  .bid-area-container {
    padding: 20px 15px;
  }
}
@media (max-width: 767px) {
  .bid-area-container .nav .nav-item {
    width: 100%;
  }
}

.bid-area-container .nav .nav-item .nav-link {
  border-radius: 53px;
  background: #f5f6f9;
  padding: 14px 23px;
  color: #9b9b9b;
  font-family: "Roboto";
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .bid-area-container .nav .nav-item .nav-link img {
    width: 15px;
  }
}

.bid-area-container .nav .nav-item .nav-link.active {
  background: #39bbff;
  color: #ffffff;
}

.bid-area-container .nav .nav-item .nav-link.active img {
  filter: brightness(100);
}

.bid-area-container .nav .nav-item .nav-link:hover {
  background: #39bbff;
  color: #ffffff;
  transition: 0.3s;
}

.bid-area-container .nav .nav-item .nav-link:hover img {
  filter: brightness(100);
}

@media (max-width: 767px) {
  .bid-area-container .nav .nav-item .nav-link {
    width: 100%;
    padding: 10px 23px;
    font-size: 16px;
    line-height: 20px;
  }
}

.bid-area-container .tab-content .tab-pane.time-wrap .nav .nav-item .nav-link {
  background-color: #ecf4fe;
  border-radius: 0;
  border-right: 1px dotted #000000;
  color: #655f5f;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.bid-area-container
  .tab-content
  .tab-pane.time-wrap
  .nav
  .nav-item
  .nav-link:hover,
.bid-area-container
  .tab-content
  .tab-pane.time-wrap
  .nav
  .nav-item
  .nav-link.active {
  background: #f5f6f9;
}

@media (max-width: 767px) {
  .bid-area-container
    .tab-content
    .tab-pane.time-wrap
    .nav
    .nav-item
    .nav-link {
    border-right: 0;
  }
}

.bid-area-container
  .tab-content
  .tab-pane.time-wrap
  .nav
  .nav-item:first-child
  .nav-link {
  border-radius: 10px 0 0 0;
}

@media (max-width: 767px) {
  .bid-area-container
    .tab-content
    .tab-pane.time-wrap
    .nav
    .nav-item:first-child
    .nav-link {
    border-radius: 15px 15px 0 0;
  }
}

.bid-area-container
  .tab-content
  .tab-pane.time-wrap
  .nav
  .nav-item:last-child
  .nav-link {
  border-right: 0;
  border-radius: 0 10px 0 0;
}

@media (max-width: 767px) {
  .bid-area-container
    .tab-content
    .tab-pane.time-wrap
    .nav
    .nav-item:last-child
    .nav-link {
    border-radius: 0;
  }
}

.bid-area-container .tab-content .tab-pane.time-wrap .tab-content.time-opt {
  background-color: #f5f6f9;
  border-radius: 30px;
  max-width: 800px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 767px) {
  .bid-area-container .tab-content .tab-pane.time-wrap .tab-content.time-opt {
    border-radius: 0 0 15px 15px;
  }
}

.bid-area-container
  .tab-content
  .tab-pane.time-wrap
  .tab-content.time-opt
  .tab-pane
  .time-fill-box
  .form-label {
  color: #655f5f;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}

.bid-area-container
  .tab-content
  .tab-pane.time-wrap
  .tab-content.time-opt
  .tab-pane
  .time-fill-box
  .form-control {
  width: 65px;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 5px;
  text-align: center;
}

.bid-area-container .tab-content .tab-pane.platform {
  background-color: #f5f6f9;
  border-radius: 30px;
  width: 100%;
  padding: 40px 50px;
}
@media (max-width: 767px) {
  .bid-area-container .tab-content .tab-pane.platform {
    padding: 30px 25px;
  }
}

.bid-area-container .tab-content .tab-pane.platform h2 {
  color: #212121;
  font-family: "Roboto";
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
}
@media (max-width: 767px) {
  .bid-area-container .tab-content .tab-pane.platform h2 {
    font-size: 18px;
    line-height: 22px;
  }
}
.bid-area-container .tab-content .tab-pane.platform .range-row {
  margin-left: -15px;
  margin-right: -15px;
}
.bid-area-container .tab-content .tab-pane.platform .range-row .range-col {
  flex: 0 0 25%;
  width: 25%;
  padding: 0 15px 35px 15px;
}

@media (max-width: 767px) {
  .bid-area-container
    .tab-content
    .tab-pane.platform
    .range-row
    .range-col:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 991px) {
  .bid-area-container .tab-content .tab-pane.platform .range-row .range-col {
    flex: 0 0 50%;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .bid-area-container .tab-content .tab-pane.platform .range-row .range-col {
    flex: 0 0 100%;
    width: 100%;
    padding-bottom: 15px;
  }
}

.bid-area-container
  .tab-content
  .tab-pane.platform
  .range-row
  .range-col
  .range-desc
  .title {
  color: #655f5f;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.bid-area-container
  .tab-content
  .tab-pane.platform
  .range-row
  .range-col
  .range-desc
  .percentage {
  color: #c4c4c4;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.bid-area-container
  .tab-content
  .tab-pane.platform
  .range-row
  .range-col
  .range-slider {
  width: 100%;
}

.bid-area-container
  .tab-content
  .tab-pane.platform
  .range-row
  .range-col
  .range-slider
  .range {
  width: 100%;
}

.bid-area-container .tab-content .tab-pane.platform.result-others {
  max-width: 650px;
  width: 100%;
  padding: 30px;
  margin: auto;
}

.bid-area-container
  .tab-content
  .tab-pane.platform.result-others
  .range-row
  .range-col {
  flex: 0 0 50%;
  width: 50%;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .bid-area-container
    .tab-content
    .tab-pane.platform.result-others
    .range-row
    .range-col {
    flex: 0 0 100%;
    width: 100%;
    padding-bottom: 15px;
  }

  .bid-area-container
    .tab-content
    .tab-pane.platform.result-others
    .range-row
    .range-col:last-child {
    padding-bottom: 0;
  }
}

.breadcrumb-set {
  margin-bottom: 30px;
}

.breadcrumb-set nav .breadcrumb .breadcrumb-item {
  font-size: 18px;
  color: #212121;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 17px;
  position: relative;
  margin-right: 10px;
  padding-left: 15px;
}

.breadcrumb-set nav .breadcrumb .breadcrumb-item a {
  color: #212121;
}

.breadcrumb-set nav .breadcrumb .breadcrumb-item.active {
  color: #39bbff;
  font-weight: 600;
}

.breadcrumb-set nav .breadcrumb .breadcrumb-item:before {
  content: "";
  background-image: url("../images/breadcrumb-arrow.svg");
  background-repeat: no-repeat;
  width: 15px;
  height: 100%;
  position: absolute;
  top: 5px;
  left: 0;
}

.breadcrumb-set nav .breadcrumb .breadcrumb-item:first-child {
  padding-left: 0;
}

.breadcrumb-set nav .breadcrumb .breadcrumb-item:first-child:before {
  display: none;
}

.add-user-wrap {
  background-color: #e8f7ff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  margin: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 30px 0px rgba(196, 196, 196, 0.25);
}
@media (max-width: 767px) {
  .add-user-wrap {
    width: 50px;
    height: 50px;
  }
}
.add-user-wrap .add-user-icon {
  position: relative;
  display: block;
  height: 100%;
}
.add-user-wrap .add-user-icon:after,
.add-user-wrap .add-user-icon:before {
  content: "";
  position: absolute;
  background-color: #39bbff;
  border-radius: 2px;
}

.add-user-wrap .add-user-icon:after {
  width: 35px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
@media (max-width: 767px) {
  .add-user-wrap .add-user-icon:after {
    width: 25px;
  }
}
.add-user-wrap .add-user-icon:before {
  width: 2px;
  height: 35px;
  left: 50%;
  top: 23px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .add-user-wrap .add-user-icon:before {
    height: 25px;
    top: 14px;
  }
}

a.save-btn:hover {
  color: #ffffff;
}

@media (max-width: 767px) {
  .add-user-btn-wrap {
    width: 100%;
  }
}
.add-user-btn-wrap .add-btn {
  color: #ffffff;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 60px;
  background: #39bbff;
  padding: 10px 38px;
  border: 1px solid #39bbff;
}

@media (max-width: 767px) {
  .add-user-btn-wrap .add-btn {
    display: block;
  }
}

.view-table.user-table .table tbody tr td {
  white-space: nowrap;
}

.add-user-container {
  padding: 45px 40px;
}

.add-user-container .form-control {
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
}

@media (max-width: 767px) {
  .add-user-container .form-control {
    padding: 10px 20px;
  }
}

@media (max-width: 767px) {
  .add-user-container {
    padding: 20px 15px;
  }
}

.add-user-container .form-select {
  background-image: url("../images/select-dropdown.svg");
}

.add-user-container .form-check-input {
  border-color: #39bbff;
}

.add-user-container .form-check-input:checked {
  background-color: #39bbff;
}

.add-user-container .form-check-label {
  font-size: 12px;
  line-height: 20px;
  color: #9b9b9b;
  font-family: "Roboto";
  font-weight: 500;
}
.link-btn-holder {
  padding-left: 5%;
}

/* .notification {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  background-color: #cce5ff; 
  border-color: #b8daff; 
  color: #007bff; 
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
} */
/* .notification {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  background-color: #cce5ff; 
  border: 1px solid #b8daff; 
  color: #007bff; 
  padding: 10px;
  width: 200px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  z-index: 999;
}

.notification-content {
  text-align: center;
} */

.notification {
  position: fixed;
  bottom: 20px; /* Adjust this to change vertical position */
  right: 20px; /* Adjust this to change horizontal position */
  background-color: #cce5ff; /* Light blue background */
  border: 1px solid #b8daff; /* Border color */
  color: #007bff; /* Blue text color */
  padding: 10px;
  width: 300px; /* Adjust width as needed */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow for depth */
  z-index: 999; /* Ensure it's above other elements */
  transition: transform 0.3s ease-in-out; /* Optional: Add a transition effect */
}

.notification-content {
  text-align: center;
  font-size: 16px; /* Adjust font size */
}

/* Additional styles when hovering over the notification */
.notification:hover {
  transform: translateY(-5px); /* Optional: Slight upward movement on hover */
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.active-pagination {
  background-color: #007bff;
  border-radius: 1rem;
  width:1.5rem;
  height:1.5rem;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}

.back-button {
  background-color: #007bff; /* Blue color */
  color: #fff; /* White text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding */
  cursor: pointer; /* Show pointer cursor on hover */
  margin-bottom: 20px; /* Add margin for spacing */
  position: absolute; /* Position absolute to align to left corner */
  bottom: 20px; /* Distance from top */
  left: 20px; /* Distance from left */
  border-radius: 5px;
}

.custom-select {
  appearance: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 60px;
  font-size: 16px;
  background-color: #f8f8f8;
  width: 200px; /* Adjust width as needed */
  text-align: center;
}

.custom-select:focus {
  outline: none;
  border-color: #6cb2eb; /* Change focus border color */
}
