@import "colors";
@import "fonts";
@import "media_queries";
@import "common";
@import "mixins";

.login-container{
  // height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  @include max(lg){
    top: unset;
    left: unset;
    transform: unset;
  }
  .content-box{
    background-color: $white;
    border-radius: 35px;
    box-shadow: 5px 5px 60px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    @include max(lg){
      box-shadow: none;
    }
    .left-container{
      width: 100%;
      @include max(md){
        height: 200px;
      }
      @include minmax(md,lg){
        height: 350px;
      }
      @include minmax(lg,xl){
        height: 100%;
      }
      img{
        height: 100%;
        width: 100%;
        @include max(xl){
          object-fit: none;
        }
      }
    }
    .right-container{
      max-width: 380px;
      @include max(md){
        padding: 30px !important;
        border-radius: 30px 30px 0 0 !important;
      }
      @include max(lg){
        margin-top: -50px;
        background-color: $white;
        border-radius: 50px 50px 0 0;
        padding: 65px 100px 30px;
        max-width: 100%;
        padding-top: 65px;
      }
      // @include max(lg){
      //   padding-right: 25px;
      // }
      @include minmax(lg,xl){
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .info-text{
        font-size: 16px;
        line-height: 18px;
        font-weight: 300;
        font-family: 'Roboto';
      }
      .password-suggest{
        .form-check-label{
          @extend .common-size;
          color:$light-black;
        }
        a{
          @extend .common-size;
          color:$skyblue;
          position: relative;
          &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 0;
            height: 2px;
            border-radius: 4px;
            background-color: $skyblue;
            transition: 0.4s;
          }
          &:hover{
            &:after{
              width: 100%;
              transition: 0.4s;
            }
          }
        }
      }
      .btn{
        &.btn-secondary{
          img{
            @include max(md){
              width: 25px
            }
          }
        }
      }
      .signup-info{
        span{
          @extend .common-size;
          color: $light-black;
        }
        a{
          @extend .common-size;
          color: $skyblue;
          position: relative;
          &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 0;
            height: 2px;
            border-radius: 4px;
            background-color: $skyblue;
            transition: 0.4s;
          }
          &:hover{
            &:after{
              width: 100%;
              transition: 0.4s;
            }
          }
        }
      }
      .otp-container{
        .form-control{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          color: #9B9B9B;
          @extend .common-size;
          @include max(md){
            width: 40px;
            height: 40px;
          }
        }
      }
      .pass-sec{
        position: relative;
        .pass-view{
          position: absolute;
          top: 50%;
          right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}