#container{
        display: flex;
        justify-content: center;
        flex-direction: row;
}
#color-list{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 5%;
        margin-left: 5%;
        width:70%;
        height:65%;
        overflow-y:scroll;
}
.text-ssp-name{
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #878787;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ssp-name:hover{
  color: #212121;
  font-weight: 600;
}
/* width */
::-webkit-scrollbar {
        width: 3px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }